import {
    ideParamMessageHandler,
    parantLocalstorageMessageHandler,
    submitHandler,
    resetHandler
} from './eventHandlers';

const btnShowYourCode       = document.querySelector('#btn-show-input');
const btnShowInputElem      = document.querySelector('#btn-show-input-elem');
export const postMessageTarget = '*';
window.addEventListener('message', ideParamMessageHandler);
window.addEventListener('message', parantLocalstorageMessageHandler);
parent.postMessage({name: 'getIdeParams'}, postMessageTarget);
// btnShowYourCode.addEventListener('click', navTabClickHandler);
// btnShowInputElem.addEventListener('click', navTabClickHandler);
const form = document.querySelector( 'form#ide-cnt' );
form.addEventListener( 'submit', submitHandler );
form.addEventListener( 'reset', resetHandler );