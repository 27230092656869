import {
    initializeView
} from './view';
import {
    fetchIdeData,
    executeCode
} from './network';

let ide = null;
let solution = null;
let language = null;
export async function ideParamMessageHandler( evt ) {
    const eventName                 = evt.data.name;
    if (eventName !== 'ideParams')
        return;
    const hasInputTab               = evt.data.hasInputTab;
    const yourCodeBtnText           = evt.data.yourCodeBtnText;
    const folderUrl                 = evt.data.folderUrl.replace('#', '%23').replace('++','%2B%2B');
    language                        = evt.data.language;
    const additionalFilesNames      = evt.data.additionalFilesNames || [];
    const t_cache                   = evt.data.t_cache;
    const ideData                   = await fetchIdeData( folderUrl, language, /*additionalFilesNames,*/ t_cache );
    //solution                        = ideData.solution;
    const template                  = ideData.template;
    //const stdin                     = ideData.stdin;
    const codeStart                 = ideData.codeStart;
    const codeEnd                   = ideData.codeEnd;
    //const additionalFiles           = ideData.additionalFiles;
    ide                             = initializeView( language, template, /*stdin, */ codeStart, codeEnd, /*additionalFiles, hasInputTab,*/ yourCodeBtnText, folderUrl );
    // debugger
}

export function parantLocalstorageMessageHandler( evt ) {
    
    const eventName = evt.data.name;
    if (eventName !== 'localstorageScrollYPositions') {
        return;
    }
    const scrollYPositionsJson = evt.data.scrollYPositionsJson;
    
    ide.setScrollYPositions( scrollYPositionsJson );
    
}

export async function submitHandler( evt ) {
    evt.preventDefault();
    ide.setVisibility( 'compile' );
    const code = ide.getCode();
    const source = code.sourceCode;
    // const hasStdIn = code.hasStdIn;
    // const stdin = code.stdin;
    // const doMultiFile = code.doMultiFile;
    // const additionalFiles = code.additionalFiles;
    //let isCorrect = null;
    let output = null;
    try {
        output = await executeCode( language, source/*, hasStdIn, stdin, doMultiFile, additionalFiles*/ );
        //isCorrect = evalOutput( output, solution );
    }
    catch( out ) {
        if ( true === isDebug ) {
            output = out.toString();
        }
        else {
            output = 'Fehler beim kompilieren / ausführen';
        }
        //isCorrect = false;
    }
    //ide.setStatus( isCorrect ? 'ok' : 'err' );
    ide.setVisibility( 'visible' );
    ide.setOutput( output );
}

export function resetHandler( evt ) {
    evt.preventDefault();
    ide.reset();
    //ide.setStatus( 'initial' );
}

// function navTabClickHandler( evt ) {
//     const btn = evt.currentTarget;
//     const identifier = btn.getAttribute('target-tab');
//     const form = ide.form;
//     form.setAttribute('active-tab', identifier);
// }