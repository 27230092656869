export async function fetchIdeData( folderUrl, language, additionalFilesNames, t_cache ) {
    const promTemp  = fetch(`${folderUrl}/template.txt?t=${t_cache}`).
        then(resp => resp.text());
    // const promSol       = fetch(`${folderUrl}/solution.json?t=${t_cache}`).
    //     then(resp => resp.json());
    // const promStdin     = fetch(`${folderUrl}/stdin.txt?t=${t_cache}`).
    //     then(resp => 
    //         (resp.status === 404) ? 
    //             undefined : 
    //             resp.text());
    const promCodeStart     = fetch(`${folderUrl}/codeStart.txt?t=${t_cache}`).
        then(resp => 
            (resp.status === 404) ? 
                '' : 
                resp.text());
    const promCodeEnd     = fetch(`${folderUrl}/codeEnd.txt?t=${t_cache}`).
        then(resp => 
            (resp.status === 404) ? 
                '' : 
                resp.text());
    // const promsAdditionalFiles = additionalFilesNames.map( additionalFileName => 
    //     fetch( `${folderUrl}/${additionalFileName}?t=${t_cache}` ).
    //         then( resp => 
    //                 (resp.status === 404) ?
    //                     isDebug ?
    //                         `404 - File with url ${resp.url} not found` : 
    //                         `404 - Datei nicht gefunden...`
    //                         :
    //                     resp.text()
    //             ).
    //             then( text => 0|| {
    //                 fileName: additionalFileName,
    //                 code: text,
    //                 identifier: 'i' + Math.floor( 10 ** 20 * Math.random() ).toString()
    //             } )
    // );
    // const promAdditionalFiles = Promise.all( promsAdditionalFiles );
    const ideParamArray = await Promise.all([promTemp,/* promSol, promStdin,*/ promCodeStart, promCodeEnd/*, promAdditionalFiles*/]);
    const ideValues = {
        template:           ideParamArray[0],
        // solution:           ideParamArray[1],
       // stdin:              ideParamArray[2],
        codeStart:          ideParamArray[1],
        codeEnd:            ideParamArray[2],
        //additionalFiles:    ideParamArray[5]
    };
    return ideValues;
}

export async function executeCode( language, source/*, hasStdIn, stdin, doMultiFile, additionalFiles*/ ) {
    // return 'Tier ist 2 Schritte gelaufen.mp';
    const executeUrl = 'exec.php';
    // console.log( arguments );
    const fd = new FormData();
    fd.append( 'lang', language );
    fd.append( 'code', source );
    // fd.append( 'hasStdin', hasStdIn );
    // fd.append( 'stdin', stdin );
    // fd.append( 'doMultiFile', doMultiFile );
    // const additionalFilesJson = JSON.stringify( additionalFiles );
    // fd.append( 'additionalFiles', additionalFilesJson );
    const response = await fetch( executeUrl, {
        method: 'post',
        body: fd
    } );
    const responseText = await response.text();
    let responseObj = null;
    try {
        responseObj = JSON.parse( responseText );
    }
    catch( e ) {
        const outputClean = `Client side error: + ${ e.toString() } \n exec.php Response: ${ responseText }`;
        throw new Error ( outputClean );
    }
    if ( undefined !== responseObj.exec_php_err ) {
        const outputClean = responseObj.exec_php_err;
        throw new Error ( `Fehler in exec.php : ${ outputClean }` );
    }
    const output64 = 
        responseObj.stderr || 
        responseObj.stdout || 
        responseObj.compile_output;
    let outputClean = '';
    try {
        const outputDirty = atob( output64 );
        outputClean = decodeURIComponent( escape( outputDirty ) );
    }
    catch ( e ) {
        throw new Error ( `Error decoding API Output \n Error: ${ e.toString() } \n Output64: ${ output64 } \n Output Dirty: ${ ( typeof outputDirty !== 'undefined' ) ? outputDirty : 'undefined' } ` );
    }
    return outputClean;
}