import { postMessageTarget } from './index';

export function initializeView( language, template, /*stdin,*/ codeStart, codeEnd,/* additionalFiles, hasInputTab,*/ yourCodeBtnText, folderUrl ) {
    const ide = {
        // aceEditors: [],
        form:                   null,
        //setStatus:              function() {throw Error('setStatus not implemented')},
        setVisibility:          function() {throw Error('setVisibility not implemented')},
        getCode:                function() {throw Error('getCode not implemented')},
        setOutput:              function() {throw Error('setOutput not implemented')},
        reset:                  function() {throw Error('reset not implemented')},
        setScrollYPositions:    function() {throw Error('setscrollYPositions not implemented')}
    };
    const form = document.querySelector( 'form#ide-cnt' );
    ide.form = form;
    // viewNamespace.procedures.ideParamsInputTabAndCodeBntTxt( hasInputTab, yourCodeBtnText );
    // additionalFiles.forEach( viewNamespace.procedures.addAdditonalFileElement );
    const aceEditors = viewNamespace.procedures.loadAce( language, template, /*stdin, additionalFiles*/ );
    // TODO: Scroll lock
    const reset = viewNamespace.procedures.resetGenerator( aceEditors, template,/* stdin, additionalFiles*/ );
    //const setStatus = viewNamespace.procedures.setStatusBarGenerator();
    const setVisibility = viewNamespace.procedures.setVisibilityGenerator();
    const getCode = viewNamespace.procedures.getCodeGenerator( aceEditors, codeStart, codeEnd, /*additionalFiles, hasInputTab*/ );
    const setOutput = viewNamespace.procedures.setOutputGenerator( aceEditors );
    const setScrollYPositions = viewNamespace.procedures.scrollLock( aceEditors, folderUrl );
    document.querySelector('body').classList.remove('hide-load');
    //ide.setStatus = setStatus;
    ide.setVisibility = setVisibility;
    ide.getCode = getCode;
    ide.reset = reset;
    ide.setOutput = setOutput;
    ide.setScrollYPositions = setScrollYPositions;
    
    return ide;
}
const viewNamespace = {};
viewNamespace.procedures = {};
viewNamespace.objects = {};
viewNamespace.procedures.addAdditonalFileElement = function ( additionalFile ) {
    const identifier = additionalFile.identifier;
    const fileName = additionalFile.fileName;
    const code = additionalFile.code;
    const ideInputHead = document.querySelector('#input-head');
    const ideInputAceCnt = document.querySelector('.code-wrap');
    // Ide Navigation Tab
    const btn = document.createElement( 'button' );
    btn.classList.add( identifier );
    btn.type = 'button';
    btn.setAttribute( 'target-tab', identifier );
    btn.addEventListener( 'click', navTabClickHandler );
    ideInputHead.append(btn);
    btn.innerText = fileName;
    const css = document.createElement('style');
    css.innerHTML = `#ide-cnt[active-tab="${identifier}"] #input-head .${identifier} {background-color: #2F3C43;}`;
    // document.head.append( css );
    // Ide Input Container
    const cnt = document.createElement( 'cnt' );
    cnt.id = identifier + '-cnt';
    cnt.classList.add( 'code-cnt', identifier );
    cnt.innerHTML = code;
    ideInputAceCnt.append( cnt );
    css.innerHTML += `#ide-cnt[active-tab="${identifier}"] .code-cnt.${identifier} {
        visibility: visible;
        pointer-events: all;
    }`;
    document.head.append( css );
};
viewNamespace.procedures.loadAce = function ( language, template, /*stdin, additionalFiles*/ ) {
    // Settings
    const aceEditors = {
        input:          null,
        //inputElement:   null,
        output:         null,
        //additionalFiles: []
    }
    const aceTheme  = 'monokai';
    // Options for both ace Editors (Input and Output)
    const aceOptGen = {
        wrap: true,
        fontSize: '1em',
    };
    const aceEditorOpt = {
        wrap: true,
        ...aceOptGen
    };
    const aceOutputOpt = {
        showLineNumbers: false,
        showGutter: false,
        highlightActiveLine: false,
        readOnly: true,
        ...aceOptGen
    };
    const aceInputCnt = document.querySelector('#ace-input-cnt')
    //const aceInputElem = document.querySelector('#ace-input-elem-cnt');
    aceInputCnt.textContent = template;
    //aceInputElem.textContent = stdin;
    language = language.replace( '#', 'sharp' ).replace( '++', '_cpp' );
    // Input
    aceEditors.input = ace.edit("ace-input-cnt", aceEditorOpt);
    aceEditors.input.setTheme(`ace/theme/${aceTheme}`);
    aceEditors.input.session.setMode(`ace/mode/${language}`);
    // InputElem 
   /* aceEditors.inputElement = ace.edit("ace-input-elem-cnt", aceEditorOpt);
    aceEditors.inputElement.setTheme(`ace/theme/${aceTheme}`);
    aceEditors.inputElement.session.setMode(`ace/mode/plain_text`);*/
    // Output
    aceEditors.output = ace.edit("ace-output-cnt", aceOutputOpt);
    aceEditors.output.setTheme(`ace/theme/${aceTheme}`);
    aceEditors.output.session.setMode(`ace/mode/plain_text`);
    aceEditors.output.renderer.$cursorLayer.element.style.display = "none";
    // Additional files
    /*aceEditors.additionalFiles = additionalFiles.map( additionalFile => {
        const identifier = additionalFile.identifier;
        const cntId = identifier + '-cnt';
        const aceEditor = ace.edit( cntId, aceEditorOpt );
        aceEditor.setTheme(`ace/theme/${aceTheme}`);
        aceEditor.session.setMode(`ace/mode/${language}`);
        // 
        return aceEditor;
    } );*/
    return aceEditors;
};
viewNamespace.procedures.resetGenerator = function( aceEditors, template, /*stdin, additionalFiles*/ ) {
    return function() {
        const aceInput = aceEditors.input;
        //const aceInputElem = aceEditors.inputElement;
        const aceOutput = aceEditors.output;
        //const aceAdditionalFiles = aceEditors.additionalFiles;
        aceInput.setValue( template );
        aceInput.clearSelection();
        //aceInputElem.setValue( stdin );
        //aceInputElem.clearSelection();
        aceOutput.setValue( '' );
        // additionalFiles.forEach( ( additionalFile, i ) => {
        //     const aceEditor = aceAdditionalFiles[ i ];
        //     const code = additionalFile.code;
        //     aceEditor.setValue( code );
        //     aceEditor.clearSelection();
        // } );
    };
};
viewNamespace.procedures.getCodeGenerator = function( aceEditors, codeStart, codeEnd, /*additionalFiles, hasInputTab*/ ) {
    // const doMultiFile = additionalFiles.length > 0;
    return function() {
        const codeBody = aceEditors.input.getValue();
        const source = codeStart + codeBody + codeEnd;
        // const stdin = aceEditors.inputElement.getValue();
        // 
        // const additionalFilesNameArr       = additionalFiles.map( additionalFile => additionalFile.fileName );
        // const additionalFilesCodeArr       = aceEditors.additionalFiles.map( aceEditor => aceEditor.getValue() );
        // const additionalFilesCodeObj           = additionalFilesNameArr.
        //     reduce( ( additionalFiles, fileName, i ) => {
        //         const code = additionalFilesCodeArr[ i];
        //         additionalFiles[ fileName ] = code;
        //         return additionalFiles;
        //     }, {} );
        return {
            sourceCode: source,
            // hasStdIn: hasInputTab,
            // stdin: stdin,
            // doMultiFile: doMultiFile,
            // additionalFiles: additionalFilesCodeObj,
        }
    }
};
viewNamespace.procedures.setVisibilityGenerator = function ( visibility ) {
    const body = document.querySelector('body');
    return function( visibility ) {
        if ( 'visible' === visibility ) {
            body.classList.remove('hide-load');
            body.classList.remove('compile');
        }
        else if ( 'hidden' === visibility ) {
            body.classList.remove('compile');
            body.classList.add('hide-load');
        }
        else if ( 'compile' === visibility ) {
            body.classList.remove('hide-load');
            body.classList.add('compile');
        }
    };
};
// viewNamespace.procedures.setStatusBarGenerator = function() {
//     const colors = ['grey', 'green', 'red'];
//     const ideStatusCnt = document.querySelector('#status-cnt');
//     const ideStatuses = document.querySelectorAll('#status-cnt > .status');
//     const okGif = document.querySelector( '#ace-status-ok-animation > img' );
//     return function (status) {
//         ideStatuses.forEach(status => status.style.display = 'none');
//         colors.forEach(color => ideStatusCnt.classList.remove(color));
//         switch (status) {
//             case 'ok':
//                 ideStatusCnt.classList.add('green');
//                 const ideStatusOk = ideStatusCnt.querySelector('.ok');
//                 ideStatusOk.style.display = 'inline';
//                 const timeoutId = setTimeout( () => {
//                     const timeoutIdOld = okGif.getAttribute( 'timeout-id' );
//                     if ( ( '' + timeoutId ) === ( '' + timeoutIdOld ) ) {
//                         okGif.style.display = 'none';
//                     }
//                 }, 2000 );
//                 okGif.src = okGif.src;
//                 okGif.setAttribute( 'timeout-id', timeoutId );
//                 okGif.removeAttribute( 'style' );
//                 break;
//             case 'err':
//                 ideStatusCnt.classList.add('red');
//                 const ideStatusErr = ideStatusCnt.querySelector('.err');
//                 ideStatusErr.style.display = 'inline';   
//                 okGif.style.display = 'none';
//                 break;
//             case 'initial':
//                 ideStatusCnt.classList.add('grey');
//                 const ideStatusInitial = ideStatusCnt.querySelector('.init');
//                 ideStatusInitial.style.display = 'inline';   
//                 okGif.style.display = 'none';
//                 break;
//             default:
//                 break;
//         }
//     };
// };
// viewNamespace.procedures.ideParamsInputTabAndCodeBntTxt = function( hasInputTab, yourCodeBtnText ) {
//     const btnShowYourCode       = document.querySelector('#btn-show-input');
//     const btnShowInputElem      = document.querySelector('#btn-show-input-elem');
//     if ( hasInputTab !== undefined ) {
//         if ( true === hasInputTab ) {
//             btnShowInputElem.removeAttribute( 'style' );
//         }
//     }    
//     if ( yourCodeBtnText !== undefined ) {
//         btnShowYourCode.innerText = yourCodeBtnText;
//     }
// };
viewNamespace.procedures.scrollLock = function( aceEditors, folderUrl ) {
    // Get Position from localstorage
    (function() {
        // Save Positions
        for ( const aceEditorKey in aceEditors ) {
            const aceEditor = aceEditors[ aceEditorKey ];
            if ( Array.isArray( aceEditor ) ) {
                aceEditor.forEach( aceEditorItem => {
                    aceEditorItem.session.on( 'changeScrollTop', scrollYPositionHandlerGenerator(  ) );
                } );
            }
            else {
                aceEditor.session.on( 'changeScrollTop', scrollYPositionHandlerGenerator(  ) );
            }
        }
        function scrollYPositionHandlerGenerator( i ) {
            const throttleMs = 500;
            let timestamp = 0;
            return function( evt ) {            
                timestamp = Date.now();
                setTimeout( () => {
                    const timeDiff = Date.now() - timestamp; 
                    if ( timeDiff < throttleMs ) {
                        return;
                    }
                    updateLocalStorage();
                } , throttleMs );
            }
        }
        function updateLocalStorage() {
            const scrollYPositions = {};
            for ( const aceEditorKey in aceEditors ) {
                const aceEditor = aceEditors[ aceEditorKey ];
                if ( Array.isArray( aceEditor ) ) {
                    scrollYPositions[ aceEditorKey ] = [];
                    aceEditor.forEach( ( aceEditorItem, i ) => {
                        const scrollPos = aceEditorItem.session.getScrollTop() ;
                        scrollYPositions[ aceEditorKey ][ i ] = scrollPos;
                    } );
                }
                else {
                    scrollYPositions[ aceEditorKey ] = null;
                    const scrollPos = aceEditor.session.getScrollTop();
                    scrollYPositions[ aceEditorKey ] = scrollPos;
                }
            }
            
            const scrollYPositonsJson = JSON.stringify( scrollYPositions );
            
            const message = { 
                name: 'setLocalstorageScrollYPositions',
                storageKey: `scrollPositions:${folderUrl}`,
                scrollYPositonsJson: scrollYPositonsJson
            };
            window.parent.postMessage( message, postMessageTarget );
            
        }
    })();
    
    const message = { 
        name: 'getLocalstorageScrollYPositions',
        storageKey: `scrollPositions:${folderUrl}`
    };
    window.parent.postMessage( message, postMessageTarget );
    
    return function( scrollYPositonsJson ) {
        
        if (null === scrollYPositonsJson) {
            return;
        }
        const scrollYPositions = JSON.parse( scrollYPositonsJson );
        for (const aceEditorKey in aceEditors) {
            const scrollY = scrollYPositions[ aceEditorKey ];
            if ( Array.isArray( scrollY ) ) {
                scrollY.forEach( ( scrollYItem, i ) => {
                    aceEditor = aceEditors[ aceEditorKey ][ i ];
                    aceEditor.session.setScrollTop( scrollYItem );     
                } );
            }
            else {
                aceEditor = aceEditors[ aceEditorKey ];
                aceEditor.session.setScrollTop( scrollY );
            }
        }
        
    };
};
viewNamespace.procedures.setOutputGenerator = function( aceEditors ) {
    const output = aceEditors.output;
    return function( txt ) {
        output.setValue( txt );
        output.clearSelection();
    }
}